@font-face {
  font-family: 'Ubuntu-Regular';
  src: url('./fonts/Ubuntu-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Ubuntu-Regular';
  overflow-x: hidden;
  overflow-y: visible;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 2em;
  margin-bottom: 0.5em;
  color: #DBE2E9;
  background-color: #101820;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
}

.column {
  flex: 1;
  min-width: 280px;
  padding: 20px;
  box-sizing: border-box;
}

.image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.profile-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15%;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
}

.social-icon {
  height: 25px;
  margin-right: 0.25em;
}

.social-link-1 {
  color: #00859B;
}

.social-link-2 {
  color: #A9431E;
}

.footer {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5em;
  font-size: 0.8em;
  color: #433A32;
  font-weight: bold;
}

.footer p, .footer a {
  display: inline;
  white-space: nowrap;
}

h1 {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

h2 {
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

hr {
  border: dashed #00859B;
  margin-bottom: 1em;
}

ul {
  padding-left: 2em;
  list-style-type: none;
}

li {
  display: flex;
  align-items: center;
  margin-bottom: 0.75em;
  list-style-type: none;
  font-size: 1.5em;
  font-weight: bold;
}

a {
  color: inherit;
  text-decoration: inherit;
  display: inherit;
  align-items: inherit;
}

@media (max-width: 700px) {
  body, html {
      padding: 0;
      margin: 0;
      display: block;
  }

  .container {
      flex-direction: column;
      align-items: stretch;
      margin: 1em;
  }

  .column {
      padding: 0.5em;
  }
}
